/* Estilos globales */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

/* Contenedor principal */
.landing-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Encabezado */
.header {
    background: #333;
    color: #fff;
    padding: 10px 0;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.logo {
    font-size: 1.5rem;
    margin: 0;
}

.nav a {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
}

.nav a:hover {
    text-decoration: underline;
}

/* Para pantallas medianas (tabletas) */
@media (max-width: 768px) {
    .nav-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .logo {
        font-size: 1.3rem;
        margin-bottom: 10px;
    }

    .nav a {
        margin: 5px 0;
    }
}

/* Para pantallas pequeñas (móviles) */
@media (max-width: 480px) {
    .nav-container {
        padding: 0 10px;
    }

    .logo {
        font-size: 1.2rem;
    }

    .nav {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .nav a {
        margin: 5px 0;
        padding: 5px 0;
        width: 100%;
        text-align: left;
    }
}

/* Sección Hero */
.hero {
    position: relative;
    text-align: center;
    color: #fff;
}

.hero-image {
    width: 100%;
    height: 80vh; /* Ajusta la altura según sea necesario */
    object-fit: cover;
}

.hero-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    padding: 0 20px;
}

.hero-text h1 {
    font-size: 3rem;
    margin: 0;
}

.hero-text p {
    font-size: 1.2rem;
}

.cta-button {
    display: inline-block;
    padding: 10px 20px;
    background: #ffff00;
    color: #000000;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 20px;
}

.cta-button:hover {
    background: #e84c1e;
}

/* Para pantallas medianas (tabletas) */
@media (max-width: 768px) {
    .hero-text h1 {
        font-size: 2.5rem;
    }

    .hero-text p {
        font-size: 1rem;
    }

    .hero-image {
        height: 60vh; /* Ajusta la altura para dispositivos más pequeños */
    }
}

/* Para pantallas pequeñas (móviles) */
@media (max-width: 480px) {
    .hero-text h1 {
        font-size: 2rem;
    }

    .hero-text p {
        font-size: 0.9rem;
    }

    .cta-button {
        padding: 8px 16px;
        font-size: 1rem;
    }

    .hero-image {
        height: 50vh; /* Más pequeño para móviles */
    }
}

/* Sección Sobre Nosotros */
.about {
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.about h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

/* Sección Características */
.features {
    background: #f4f4f4;
    padding: 40px 20px;
}

.features h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
}

.features .feature-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.features img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 20px;
}

.features h3 {
    margin: 10px;
    font-size: 1.5rem;
}

.features p {
    margin: 0;
}


/* Para pantallas medianas (tabletas) */
@media (max-width: 768px) {
    .features .feature-item {
        flex-direction: column;
        text-align: center;
    }

    .features img {
        margin-bottom: 20px;
        margin-right: 0;
    }

    .features h3 {
        font-size: 1.4rem;
    }

    .features p {
        font-size: 1rem;
    }
}

/* Para pantallas pequeñas (móviles) */
@media (max-width: 480px) {
    .features .feature-item {
        padding: 0 10px;
    }

    .features img {
        width: 80px;
        height: 80px;
    }

    .features h3 {
        font-size: 1.2rem;
    }

    .features p {
        font-size: 0.9rem;
    }
}
/* Sección Galería */
.gallery {
    background: #333;
    color: #fff;
    text-align: center;
    padding: 40px 20px;
}

.gallery h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.gallery-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 1200px;
    margin: 0 auto;
}

.gallery-item {
    width: 200px;
    height: 200px;
    object-fit: cover;
}