.blog-list {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.blog-header {
    text-align: center;
    margin-bottom: 40px;
}

.blog-header h1 {
    font-size: 2.5rem;
    margin: 0;
}

.blog-header p {
    font-size: 1.2rem;
    color: #666;
}

.blog-posts {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.blog-post {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.blog-post-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.blog-post-content {
    padding: 20px;
}

.blog-post-title {
    font-size: 1.8rem;
    margin: 0 0 10px;
}

.blog-post-excerpt {
    font-size: 1rem;
    color: #444;
}

.read-more {
    display: inline-block;
    margin-top: 10px;
    color: #ff5733;
    text-decoration: none;
}

.read-more:hover {
    text-decoration: underline;
}
