.navbar {
  background-color: #2c3e50;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icono{
  display: inline-flex;
}

.navbar h1 {
  margin-left: 10px;
  color: #ecf0f1;
}

.navbar ul {
  display: flex;
  gap: 1rem;
}

.navbar ul li a {
  color: #ecf0f1;
  font-weight: bold;
  transition: color 0.3s ease;
}

.navbar ul li a:hover {
  color: #3498db;
}

.logoIMG{
  width: 2.5rem;
  height: 2.5rem;
}