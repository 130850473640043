.blog-post-detail {
    max-width: 800px; /* Ancho máximo del post */
    margin: 0 auto; /* Centra el post en la página */
    margin-top: 15vh;
    margin-bottom: 15vh;
    padding: 20px;
    background: #ffffff; /* Fondo blanco para el post */
    border: 1px solid #ddd; /* Borde sutil */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra sutil */
    font-family: Arial, sans-serif; /* Fuente de texto */
}

.blog-post-header {
    margin-bottom: 20px; /* Espacio debajo del header */
}

.blog-post-header h1 {
    font-size: 2.5rem; /* Tamaño grande del título */
    color: #333; /* Color del texto del título */
    margin: 0; /* Sin margen */
}

.blog-post-date {
    font-size: 1rem; /* Tamaño de fuente del date */
    color: #888; /* Color gris para la fecha */
    margin-top: 5px; /* Espacio encima de la fecha */
}

.blog-post-detail-image {
    max-width: 100%; /* Asegura que la imagen no se desborde */
    height: auto; /* Mantiene la proporción de la imagen */
    border-radius: 8px; /* Bordes redondeados para la imagen */
    margin: 20px 0; /* Espacio arriba y abajo de la imagen */
}

.blog-post-detail-content {
    line-height: 1.6; /* Altura de línea para mayor legibilidad */
    color: #333; /* Color del texto del contenido */
}

.blog-post-detail-content p {
    margin-bottom: 20px; /* Espacio debajo de cada párrafo */
}

.blog-post-detail-content a {
    color: #ff5733; /* Color de los enlaces */
    text-decoration: none; /* Sin subrayado */
}

.blog-post-detail-content a:hover {
    text-decoration: underline; /* Subrayado al pasar el cursor */
}

.blog-post-detail-content blockquote {
    border-left: 4px solid #ff5733; /* Borde izquierdo con color */
    padding-left: 20px; /* Espacio a la izquierda del texto */
    margin: 20px 0; /* Espacio arriba y abajo del bloque de cita */
    font-style: italic; /* Estilo en cursiva */
    color: #555; /* Color del texto de la cita */
}
