.popup-content {
    max-width: 200px;
    text-align: center;
    font-family: Arial, sans-serif;
}

.popup-image {
    width: 100%; /* Asegura que la imagen ocupe el 100% del ancho disponible */
    height: auto; /* Mantiene la proporción de la imagen */
    border-radius: 5px;
    margin-bottom: 10px;
}

.popup-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
}

.popup-description {
    font-size: 14px;
    color: #555;
    margin-bottom: 10px;
}


.popup-link:hover {
    background-color: #0056b3;
}
